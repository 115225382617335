<template>
  <Dialog
    v-model:visible="displayModal"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '60vw'}"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-pencil" style="font-size: 1rem;margin-right:10px"></i>
          <strong style="font-size:20px">Asignación Lotes</strong>
        </h4>
      </div>
    </template>
    <div class="grid grid-cols-7 text-xxs bg-gray-300 rounded p-2">
      <div class="flex flex-col col-span-4">
        <div class="font-bold">
          Código Mx: <span class="font-light">{{ dataModalLotes.ItemName }}</span>
        </div>
        <div class="font-bold">
          Nombre Mx: <span class="font-light">{{ dataModalLotes.ItemCode }}</span>
        </div>
      </div>
      <div class="flex flex-col">
        <strong> Cantidad en Inventario</strong>
        <span>{{ $h.formatNumber(dataModalLotes.InventarioBod) }}</span>
      </div>
      <div class="flex flex-col">
        <strong> Cantidad en Documento</strong>
        <span>{{ $h.formatNumber(dataModalLotes.Quantity) }}</span>
      </div>
      <div class="flex flex-col">
        <strong> Cantidad disponible</strong>
        <span>{{ onQuantity }}</span>
      </div>
    </div>
    <div class="grid grid-cols-7 text-xxs rounded gap-2">
      <div class="col-span-4 flex align-items-center mt-4">
        <Dropdown
          v-model="loteTemp.DistNumber"
          @change="OnChangeLote($event)"
          class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xs"
          @click="$h.xxsInput"
          panelClass="text-xxs"
          optionLabel="DistNumber"
          :options="loteDisponible"
          optionValue="DistNumber"
          placeholder="Seleccione un lote en devolución..."
        />
      </div>
      <div class="flex align-items-center mt-4">
        <input :disabled="true"
               style="padding: 0.30rem;"
               class="p-button-secondary border rounded-md p-inputtext-xs w-full"
               v-model="loteTemp.ExpDate"
               type="date">
      </div>
      <div class="flex align-items-center mt-4">
          <span class="p-input-icon-left">
            <InputNumber :step="1" class="p-inputtext-xs " inputId="minmax"
                         v-model="loteTemp.CantidadLote"
                         showButtons buttonLayout="horizontal" :min="1"
                         decrementButtonClass="bg-blue-800" incrementButtonClass="bg-blue-800"
                         incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
          </span>
      </div>
      <div class="flex justify-end align-items-center mt-4">
          <span class="p-input-icon-left">
            <Button icon="pi pi-plus" label="Agregar Lote" @click="addBatchNumber"
                    class="p-button-outlined p-button-success p-button-xs"/>
          </span>
      </div>
    </div>
    <div class="pt-4">
      <DataTable
        :value="dataModalLotes.BatchNumbers"
        class="p-datatable-sm text-xxs"
        showGridlines
        dataKey="id"
        responsiveLayout="scroll"
        :scrollable="true"
        scrollHeight="500px"
      >
        <template #empty>
          No hay datos para mostrar
        </template>
        <Column field="BatchNumber" header="Lotes" headerStyle="justify-content: center;" style="min-width:20rem"
                bodyStyle="justify-content: center;text-align: center">
          <template #body="{data}">
            <div>
              {{ data.DistNumber }}
            </div>
          </template>
        </Column>
        <Column field="CantidadLote" header="Cantidad" headerStyle="justify-content: center;" style="max-width:20rem"
                bodyStyle="text-align: center;justify-content: center">
          <template #body="{data}">
            <div class="flex justify-center">
              {{ data.CantidadLote }}
            </div>
          </template>
        </Column>
        <Column field="ExpDate" header="Fecha Vencimiento" headerStyle="justify-content: center;"
                style="max-width:20rem"
                bodyStyle="justify-content: center;text-align: center">
          <template #body="{data}">
            <div>
              {{ data.ExpDate }}
            </div>
          </template>
        </Column>
        <Column field="Ubicacion" header="Ubicación" headerStyle="justify-content: center;" style="max-width:30rem"
                bodyStyle="justify-content: center;text-align: center">
          <template #body="{data}">
            <div>
              {{ data.Ubicacion }}
            </div>
          </template>
        </Column>
        <Column field="" header="Acciones" headerClass="lg:text-xs"
                bodyStyle="justify-content: center;text-align: center">
          <template #body="slotProps">
            <Button icon="pi pi-trash" v-tooltip.top="'Eliminar lotes'"
                    iconClass="text-xl"
                    @click="deleteLine(slotProps)"
                    class="p-button-text p-button-danger">
            </Button>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="flex justify-center mt-4">
      <Button icon="pi pi-check-square" label="Confirmar" @click="confirmLine" class="p-button-outlined p-button-xs"/>
    </div>
  </Dialog>
</template>

<script>
import { computed, ref } from 'vue'
import Swal from 'sweetalert2'

export default {
  name: 'modalAsignarLotesDevolucion',
  setup () {
    const displayModal = ref(false)
    const dataModalLotes = ref({})
    const loteTemp = ref({})
    const onQuantity = computed(() => {
      const resultCantidad = dataModalLotes.value.BatchNumbers.reduce((total, current) => {
        return total + current.CantidadLote
      }, 0)
      return dataModalLotes.value.Quantity - resultCantidad
    })
    const loteDisponible = computed(() => {
      if (!dataModalLotes.value.lotes) return []
      return dataModalLotes.value.lotes.filter((x) => x.visible)
    })
    const OnChangeLote = ({ value }) => {
      const findLote = dataModalLotes.value.lotes.find((x) => x.DistNumber === value)
      loteTemp.value.ExpDate = findLote.ExpDate
      loteTemp.value.AbsEntry = findLote.AbsEntry
      loteTemp.value.CantidadLote = findLote.CantidadLote
      loteTemp.value.ExpDate = findLote.ExpDate
      loteTemp.value.Ubicacion = findLote.Ubicacion
    }
    const openModal = (data) => {
      dataModalLotes.value = data
      displayModal.value = true
    }
    const addBatchNumber = () => {
      if (!loteTemp.value.DistNumber) {
        Swal.fire({
          icon: 'info',
          text: 'Por favor seleccione un lote e inténtelo de nuevo.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      dataModalLotes.value.BatchNumbers.push({
        AbsEntry: loteTemp.value.AbsEntry,
        CantidadLote: loteTemp.value.CantidadLote,
        DistNumber: loteTemp.value.DistNumber,
        ExpDate: loteTemp.value.ExpDate,
        Ubicacion: loteTemp.value.Ubicacion
      })
      const index = dataModalLotes.value.lotes.findIndex((x) => x.DistNumber === loteTemp.value.DistNumber)
      dataModalLotes.value.lotes[index].visible = false
      loteTemp.value.DistNumber = null
      loteTemp.value.AbsEntry = null
      loteTemp.value.CantidadLote = null
      loteTemp.value.ExpDate = null
      loteTemp.value.Ubicacion = null
    }
    const deleteLine = ({ index, data }) => {
      Swal.fire({
        icon: 'question',
        title: 'Esta seguro/a?',
        text: '¿Desea eliminar este lote de la asignación de devolución?',
        showConfirmButton: true,
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar'
      }).then(({ value }) => {
        if (value) {
          const findIndex = dataModalLotes.value.lotes.findIndex((x) => x.DistNumber === data.DistNumber)
          dataModalLotes.value.lotes[findIndex].visible = true
          dataModalLotes.value.BatchNumbers.splice(index, 1)
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'Lote eliminado correctamente',
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          })
        }
      })
    }
    const confirmLine = () => {
      displayModal.value = false
    }
    return {
      displayModal,
      openModal,
      dataModalLotes,
      addBatchNumber,
      onQuantity,
      OnChangeLote,
      confirmLine,
      loteTemp,
      loteDisponible,
      deleteLine
    }
  }
}
</script>

<style scoped>
::v-deep(.p-inputnumber-input) {
  width: 3rem;
}

::v-deep(.p-inputnumber-buttons-horizontal) {
  justify-content: center;
}
</style>
